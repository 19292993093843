import { type FC, useState, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { PageSpinner } from 'dibs-elements/exports/PageSpinner';
import OktaWidget from './OktaWidget';

import { loadOkta } from '../helpers/okta';

import styles from './OktaWidgetLoader.scss';

const OktaWidgetLoader: FC<{ isVisible: boolean; oktaOpened: boolean }> = ({
    isVisible,
    oktaOpened,
}) => {
    const environment = useRelayEnvironment();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isVisible) {
            const currentParams = new URLSearchParams(window.location.search);
            if ((currentParams.get('login') || '').toLowerCase() !== 'okta') {
                currentParams.append('login', 'okta');
                const pageUrl = `?${currentParams.toString()}`;
                window.history.pushState('', '', pageUrl);
            }
        } else {
            const currentParams = new URLSearchParams(window.location.search);
            if ((currentParams.get('login') || '').toLowerCase() === 'okta') {
                currentParams.delete('login');
                const pageUrl = `?${currentParams.toString()}`;
                window.history.pushState('', '', pageUrl);
            }
        }
    }, [isVisible]);

    useEffect(() => {
        if (!window.OktaSignIn) {
            setIsLoading(true);
            const load = async (): Promise<void> => {
                await loadOkta(environment, setHasError);
                setIsLoading(false);
            };

            load();
        }
    }, [environment]);

    if (hasError) {
        return (
            <div className={styles.error}>
                <h4>Something went wrong while trying to load Okta.</h4>
            </div>
        );
    }

    if (isLoading) {
        return <PageSpinner />;
    }

    if (!window.OktaSignIn || !oktaOpened) {
        return null;
    }

    return <OktaWidget />;
};

export default OktaWidgetLoader;
