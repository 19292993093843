import { type FunctionComponent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

const LoginSuspiciousErrorMessage: FunctionComponent = () => (
    <FormattedMessage
        defaultMessage="We've detected suspicious login activity on this device. Please contact support@1stdibs.com"
        id="login.message.login_suspicious"
    />
);

export default LoginSuspiciousErrorMessage;
