import { loadScript } from './utils/loadScript';
import { type InternalLoadPayload, type WidgetParams } from './types';

import { okta } from './internal/okta';

export type InternalLoaderScripts = {
    [key in InternalLoadPayload]: (options?: WidgetParams) => Promise<void>;
};

const scripts: InternalLoaderScripts = {
    okta,
};

const cachedLoaders: Partial<Record<keyof InternalLoaderScripts, Promise<void>>> = {};

const widgetLoader = {
    load: (type: keyof InternalLoaderScripts, options?: WidgetParams): Promise<void> =>
        loadScript({ type, options, cachedLoaders, scripts }),
};

export default widgetLoader;

export * from './types';
