import { type Environment } from 'react-relay';
import widgetLoader from 'dibs-widget-loader/exports/internal';

export const loadOkta = async (
    environment: Environment,
    setHasError: (hasError: boolean) => void
): Promise<void> => {
    try {
        await widgetLoader.load('okta', { relayEnvironment: environment });
    } catch (err) {
        setHasError(true);
    }
};
