import injectScript, { injectCss } from '../utils/injectScript';
import { getSocialNetwork } from '../utils/getSocialNetwork';
import { type WidgetParams } from '../types';

import { ERROR_MESSAGES } from '../constants';

export const okta = async (options?: WidgetParams): Promise<void> => {
    if (!options || !options.relayEnvironment) {
        throw new Error(ERROR_MESSAGES.GOOGLE_OPTIONS_NOT_PROVIDED);
    }

    const oktaConfig = await getSocialNetwork.byName('OKTA', options.relayEnvironment);

    if (!oktaConfig) {
        throw new Error(ERROR_MESSAGES.OKTA_APP_NOT_AVAILABLE);
    }

    if (!oktaConfig.parameters || !oktaConfig.parameters.clientId) {
        throw new Error(ERROR_MESSAGES.MISSING_SOCIAL_NETWORK_PARAMETERS);
    }

    await injectScript('//global.oktacdn.com/okta-signin-widget/5.11.1/js/okta-sign-in.min.js');
    injectCss('https://global.oktacdn.com/okta-signin-widget/5.9.4/css/okta-sign-in.min.css');

    window.OktaWidget = new window.OktaSignIn({
        baseUrl: 'https://okta.1stdibs.com',
        clientId: oktaConfig.parameters.clientId,
        redirectUri: `${window.location.origin}/internal`,
        helpSupportNumber: null,
        logo: 'https://a.1stdibscdn.com/dist/adhoc/logo/1stdibs-logo.png',
        language: 'en',
        i18n: {
            en: {
                'primaryauth.submit': 'Log In',
                'primaryauth.title': 'Log in using your Okta credentials.',
            },
        },
        authParams: {
            issuer: 'https://okta.1stdibs.com',
            pkce: false,
            responseType: 'token',
        },
        features: {
            registration: false,
            autoPush: true,
            showPasswordToggleOnSignInPage: true,
            idpDiscovery: true,
        },
        idpDiscovery: {
            requestContext: window.location.href,
        },
        colors: {
            brand: '#000000',
        },
    });
};
