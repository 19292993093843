import { Fragment, type FunctionComponent, type ReactNode, useState, useEffect } from 'react';
import { useRelayEnvironment, useLazyLoadQuery, graphql } from 'react-relay';
import classnames from 'classnames';

import ArrowDown from 'dibs-icons/exports/legacy/ArrowDown';
import { Link } from 'dibs-elements/exports/Link';
import RecaptchaContainer, {
    isValidErrorCode,
    mfaActionTypes,
    type AllowedMFAErrorCodes,
    type MFAActionType,
} from 'dibs-recaptcha/exports/RecaptchaContainer';
import serverVars from 'server-vars';

import LoginFormWrapper from '../LoginFormWrapper';
import LoginMessage, { type LoginMessageType } from '../LoginMessage';
import LoginFormGroup from '../inputs/LoginFormGroup';
import LoginEmailInput from '../inputs/LoginEmailInput';
import LoginPasswordInput from '../inputs/LoginPasswordInput';
import LoginSubmit from '../inputs/LoginSubmit';
import LoginResetPasswordLink from '../inputs/LoginResetPasswordLink';
import InternalLoginDealerLink from './InternalLoginDealerLink';
import { useLoginFormState } from '../helpers/useLoginFormState';
import { internalLoginMutation, type InternalLoginInput } from './mutations/internalLoginMutation';
import LoginServerErrorMessage from '../LoginServerErrorMessage';
import LoginInvalidErrorMessage from '../LoginInvalidErrorMessage';
import LoginSuspiciousErrorMessage from '../LoginSuspiciousErrorMessage';
import { getGoToLocation } from '../helpers/loginGoTo';
import { useLoginMutation } from '../helpers/useLoginMutation';
import { type DibsRelayNetworkError } from 'dibs-relay-network-layer';

import OktaLoginButton from '../okta/OktaLoginButton';
import OktaWidgetLoader from '../okta/OktaWidgetLoader';

import styles from './styles/InternalLoginForm.scss';

import { type internalLoginMutation as internalLoginMutationType } from './mutations/__generated__/internalLoginMutation.graphql';
import { type InternalLoginFormQuery } from './__generated__/InternalLoginFormQuery.graphql';

type Props = {
    successMessage?: ReactNode;
};

const internalUserEmail = new RegExp('^[A-Za-z0-9._%+-]+@1stdibs.com$');

function isInternalUser(email: string): boolean {
    return internalUserEmail.test(email);
}

function getErrorMessage(
    errorCode: boolean | DibsRelayNetworkError
): string | boolean | null | undefined {
    if (typeof errorCode !== 'boolean') {
        return errorCode?.source?.errors?.[0]?.message;
    }
    return errorCode;
}

// uncomment once interal login + MFA is integrated
function isMFAError(errorCode: boolean | DibsRelayNetworkError): boolean {
    const errorMsg = getErrorMessage(errorCode);
    return typeof errorMsg === 'string' && errorMsg.startsWith('MFA_');
}

function isSuspiciousLogin(errorCode: boolean | DibsRelayNetworkError): boolean {
    const errorMsg = getErrorMessage(errorCode);
    if (typeof errorMsg === 'string') {
        return errorMsg === 'SUSPICIOUS_ACTIVITY';
    }
    return false;
}

function isInvalidLogin(errorCode: boolean | DibsRelayNetworkError): boolean {
    const errorMsg = getErrorMessage(errorCode);
    if (typeof errorMsg === 'string') {
        return errorMsg === 'INVALID_LOGIN';
    }
    return false;
}

function isOktaLogin(): boolean {
    const searchParams = new URLSearchParams(window.location.search);
    return (searchParams.get('login') || '').toLowerCase() === 'okta';
}

const InternalLoginForm: FunctionComponent<Props> = ({ successMessage }) => {
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showOktaWidget, setShowOktaWidget] = useState(() => isOktaLogin());
    const [oktaOpened, setOktaOpened] = useState(() => isOktaLogin());
    const [message, setMessage] = useState<ReactNode>('');
    const [messageType, setMessageType] = useState<LoginMessageType>('success');
    const { errors, isValid, values, touched, handleChange, handleFieldBlur } = useLoginFormState({
        formType: 'login',
        isInternal: true,
    });
    const environment = useRelayEnvironment();
    const { email } = values;
    const isInternalUserEmail = isInternalUser(email);

    const { viewer } = useLazyLoadQuery<InternalLoginFormQuery>(
        graphql`
            query InternalLoginFormQuery {
                viewer {
                    useOktaLogin
                }
            }
        `,
        {}
    );

    const { useOktaLogin } = viewer || {};

    // MFA
    const [displayRecaptcha, setDisplayRecaptcha] = useState(false);
    const [mfaVerificationCode, setMFAVerificationCode] = useState<string | null>(null);
    const [mfaErrorCode, setMFAErrorCode] = useState<AllowedMFAErrorCodes | null>(null);
    const [mfaActionType, setMFAActionType] = useState<MFAActionType>(mfaActionTypes.displayed);
    // should only auto close when attempts exceeded
    const [mfaShouldAutoClose, setMFAShouldAutoClose] = useState(false);

    const resetMFADefaults = (): void => {
        // allows for the RecaptchaContainer
        // component to reset to default data
        // when toggling views
        setDisplayRecaptcha(false);
        setMFAVerificationCode(null);
        setMFAErrorCode(null);
        setMFAShouldAutoClose(false);
    };
    // end MFA

    const { errorCode, hasSubmitted, isSaving, handleSubmit } = useLoginMutation<
        internalLoginMutationType,
        InternalLoginInput,
        boolean
    >({
        isValid,
        mutationFn: internalLoginMutation,
        variables: {
            email,
            password: values.password,
            mfaVerificationCode,
            cookieDomain: serverVars.get('cookieDomain'),
        },
        getErrorCode: () => false, // no errorcodes from this mutation
        environment,
        handleFieldBlur,
        onSuccess: () => {
            setMFAActionType(mfaActionTypes.displayed);
            window.location.assign(getGoToLocation('/internal/'));
        },
    });

    useEffect(() => {
        if (errorCode) {
            setMessageType('error');
            if (isMFAError(errorCode)) {
                const msg = getErrorMessage(errorCode);
                if (isValidErrorCode(msg)) {
                    setMFAErrorCode(msg as AllowedMFAErrorCodes);
                    setDisplayRecaptcha(true);
                    if (msg === 'MFA_CODE_ATTEMPTS_EXCEEDED') {
                        setMFAShouldAutoClose(true);
                    }
                }
            } else if (isSuspiciousLogin(errorCode)) {
                setMessage(<LoginSuspiciousErrorMessage />);
            } else if (isInvalidLogin(errorCode)) {
                setMessage(<LoginInvalidErrorMessage />);
            } else {
                setMessage(<LoginServerErrorMessage />);
            }
        } else if (successMessage && !hasSubmitted) {
            setMessage(successMessage);
        } else if (isInternalUserEmail) {
            if (useOktaLogin) {
                setMessageType('error');
                setMessage(
                    <div>
                        1stDibs employees must log in using Okta.{' '}
                        <Link
                            href="https://internalsupport.1stdibs.com/hc/en-us/articles/360050799572"
                            target="_blank"
                            className={styles.internalErrorLink}
                        >
                            Need more info?
                        </Link>
                    </div>
                );
            } else {
                setMessageType('success');
                setMessage(
                    <div>
                        Non qa, stage, and prod servers can use the email/password login
                        functionality.
                    </div>
                );
            }
        }
    }, [errorCode, hasSubmitted, isInternalUserEmail, useOktaLogin, successMessage]);

    useEffect(() => {
        window.onpopstate = () => {
            setShowOktaWidget(isOktaLogin());
        };
    }, []);

    const socialLoginButton = useOktaLogin ? (
        <OktaLoginButton
            key="okta-login-button"
            onClick={() => {
                setShowOktaWidget(true);
                setOktaOpened(true);
            }}
        />
    ) : null;

    return (
        <Fragment>
            {useOktaLogin && (
                <div className={classnames({ [styles.hide]: !showOktaWidget })}>
                    <OktaWidgetLoader isVisible={showOktaWidget} oktaOpened={oktaOpened} />
                </div>
            )}
            <div className={classnames({ [styles.hide]: useOktaLogin && showOktaWidget })}>
                {message && <LoginMessage messageType={messageType} message={message} />}
                <LoginFormWrapper
                    heading="1stDibs Administration Login"
                    socialLoginButtons={socialLoginButton}
                    handleSubmit={handleSubmit}
                >
                    <div
                        className={classnames(styles.loginLinksWrapper, {
                            [styles.dealerLinkOnly]: !useOktaLogin,
                        })}
                    >
                        {useOktaLogin && (
                            <Link
                                onClick={() => setShowLoginForm(!showLoginForm)}
                                dataTn="email-password-login"
                                className={styles.passwordLoginCTA}
                            >
                                Login with Password
                                <ArrowDown
                                    className={classnames(styles.arrowDown, {
                                        [styles.arrowUp]: showLoginForm,
                                    })}
                                />
                            </Link>
                        )}
                        <InternalLoginDealerLink />
                    </div>

                    {(showLoginForm || !useOktaLogin) && (
                        <>
                            {displayRecaptcha ? (
                                <RecaptchaContainer
                                    email={email}
                                    resetMFAData={resetMFADefaults}
                                    mfaVerificationCode={mfaVerificationCode}
                                    setMFAVerificationCode={setMFAVerificationCode}
                                    errorCode={mfaErrorCode} // : MFAErrorCodes | null;
                                    mfaActionType={mfaActionType} //: typeof mfaActionTypes[keyof typeof mfaActionTypes];
                                    isMobile={false}
                                    trackingCategory="login"
                                    trackingLabel="external resource login form" //string
                                    shouldAutoClose={mfaShouldAutoClose}
                                />
                            ) : (
                                <>
                                    <LoginFormGroup>
                                        <LoginEmailInput
                                            value={email}
                                            touched={touched.email}
                                            error={errors.email}
                                            onBlur={handleFieldBlur}
                                            onChange={handleChange}
                                        />
                                    </LoginFormGroup>
                                    <LoginFormGroup>
                                        <LoginPasswordInput
                                            value={values.password}
                                            touched={touched.password}
                                            error={errors.password}
                                            onBlur={handleFieldBlur}
                                            onChange={handleChange}
                                        />
                                    </LoginFormGroup>
                                    <LoginResetPasswordLink href="/login/reset_pw/internal/new" />
                                </>
                            )}
                            <LoginSubmit isSaving={isSaving} />
                        </>
                    )}
                </LoginFormWrapper>
            </div>
        </Fragment>
    );
};

export default InternalLoginForm;
