import { type FC } from 'react';

import { Button } from 'dibs-elements/exports/Button';

import styles from './OktaLoginButton.scss';

const OktaLoginButton: FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <div className={styles.buttonWrapper}>
            <Button onClick={onClick} type="primaryAlt" fullWidth className={styles.button}>
                LOG IN WITH OKTA
            </Button>
        </div>
    );
};

export default OktaLoginButton;
